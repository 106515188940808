const state = {
  menuItems: [],
};

const actions = {
  setHeaderMenuItems({ commit }, payload) {
    commit("setHeaderMenuItems", payload);
  },
};

const mutations = {
  setHeaderMenuItems(state, payload) {
    state.menuItems = payload;
  },
};

const getters = {
  getHeaderMenuItems(state) {
    return state.menuItems;
  },
};

export const common = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
