import http from "@/services/http.service";
import {
  FINANCIALMEMBERS,
  TRANSACTIONHISTORY,
  CLEARDUEPAYMENT,
} from "@/constants/apiUrls";

const getMembers = async (commit, payload) => {
  const response = await http.get(FINANCIALMEMBERS, {
    params: { ...payload },
  });
  commit("setMembers", response.data);
  commit("setMeta", response.data);
};

const getTransactionHistory = async (commit, payload) => {
  const response = await http.get(
    TRANSACTIONHISTORY.replace("id", payload.id),
    {
      params: { page: payload.page },
    }
  );
  commit("setTransactionHistory", response.data);
  commit("setTransactionMeta", response.data);
};

const clearDuePayment = async (commit, payload) => {
  await http.post(CLEARDUEPAYMENT.replace("id", payload.id), {
    amount: payload.amount,
    ends_at: payload.ends_at,
    description: payload.description,
  });
};

export default {
  getMembers,
  getTransactionHistory,
  clearDuePayment,
};
