import newsService from "@/services/news.service";

const state = {
  newsList: [],
  meta: {},
  newsMediaResponse: [],
};

const actions = {
  getNewsList({ commit }, payload) {
    return newsService.getNewsList(commit, payload);
  },

  createNews({ commit }, payload) {
    return newsService.createNews(commit, payload);
  },

  deleteNews({ commit }, payload) {
    return newsService.deleteNews(commit, payload);
  },

  updateNews({ commit }, payload) {
    return newsService.updateNews(commit, payload);
  },

  uploadMedia({ commit }, query) {
    return newsService.uploadMedia(commit, query);
  },
};

const mutations = {
  setMeta(state, response) {
    state.meta = response.meta;
  },

  setNewsList(state, response) {
    state.newsList = response.data;
  },

  setNewsMediaResponse(state, response) {
    state.newsMediaResponse = response.data;
  },
};

const getters = {
  getMeta(state) {
    return state.meta;
  },

  getNewsList(state) {
    return state.newsList;
  },

  getNewsMediaResponse(state) {
    return state.newsMediaResponse;
  },
};

export const news = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
