import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@/scss/vuetify/overrides.scss";
Vue.use(Vuetify);

const theme = {
  primary: "#1b5e20",
  info: "#1b5e20",
  success: "#21c1d6",
  accent: "#1b5e20",
  default: "#1b5e20",
};

export default new Vuetify({
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
