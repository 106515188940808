import associateService from "@/services/associate.service";

const state = {
  associates: [],
  associateMeta: {},
};

const actions = {
  getAssociates({ commit }, payload) {
    return associateService.getAssociates(commit, payload);
  },

  deleteAssociate({ commit }, payload) {
    return associateService.deleteAssociate(commit, payload);
  },

  updateAssociate({ commit }, payload) {
    return associateService.updateAssociate(commit, payload);
  },
};

const mutations = {
  setAssociates(state, response) {
    state.associates = response.data;
  },

  setAssociateMeta(state, response) {
    state.associateMeta = response.meta;
  },
};

const getters = {
  getAssociates(state) {
    return state.associates;
  },

  getAssociateMeta(state) {
    return state.associateMeta;
  },
};

export const associates = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
