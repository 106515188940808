import http from "@/services/http.service";
import { MEMBERS } from "@/constants/apiUrls";
import { toUsPhone } from "../utils/number.utility.js";

const getAssociates = async (commit, payload) => {
  const response = await http.get(MEMBERS, {
    params: { type: "associate", ...payload, user_type: 2 },
  });
  response.data.data.forEach((item) => {
    item.user_detail.representative_phone = toUsPhone(
      item.user_detail.representative_phone
    );
  });
  commit("setAssociates", response.data);
  commit("setAssociateMeta", response.data);
};

const deleteAssociate = async (commit, payload) => {
  await http.delete(MEMBERS + "/" + payload.id);
};

const updateAssociate = async (commit, payload) => {
  await http.put(MEMBERS + "/" + payload.id, { ...payload, user_type: 2 });
};

export default {
  getAssociates,
  deleteAssociate,
  updateAssociate,
};
