import http from "@/services/http.service";
import {
  BODYSHOPS,
  SERVICES,
  MEMBER_CHANGE_PASSWORD,
  BODYSHOPSLIST,
  GET_CHAT_USER,
  ROOMS,
} from "@/constants/apiUrls";
import { toUsPhone } from "../utils/number.utility.js";

const getBodyshops = async (commit, payload) => {
  const response = await http.get(BODYSHOPS, {
    params: { type: "bodyshops", ...payload, user_type: 4 },
  });
  response.data.data.forEach((item) => {
    item.user_detail.company_phone = item.user_detail
      ? toUsPhone(item.user_detail.company_phone)
      : "";
    item.user_detail.representative_phone = item.user_detail
      ? toUsPhone(item.user_detail.representative_phone)
      : "";
    item.roomId = item.rooms.length ? item.rooms[0].id : "";
  });
  commit("setBodyshops", response.data);
  commit("setMeta", response.data);
};

const getBodyshopsList = async (commit) => {
  const response = await http.get(BODYSHOPSLIST, {
    params: { type: "bodyshops", user_type: 4 },
  });
  commit("setBodyshopsList", response.data);
};

const createBodyshops = async (commit, payload) => {
  await http.post(BODYSHOPS, { ...payload, user_type: 4 });
};

const deleteBodyshops = async (commit, payload) => {
  await http.delete(BODYSHOPS + "/" + payload.id);
};

const updateBodyshops = async (commit, payload) => {
  await http.put(BODYSHOPS + "/" + payload.id, { ...payload, user_type: 4 });
};

const getBodyshopsServices = async (commit) => {
  const response = await http.get(SERVICES);
  commit("setBodyshopsServices", response.data);
};

const activateBodyshops = async (commit, payload) => {
  await http.put(BODYSHOPS + "/" + payload.id + "/activation", {
    ...payload,
    user_type: 4,
  });
};

const updateBodyshopsPassword = async (commit, payload) => {
  await http.put(MEMBER_CHANGE_PASSWORD + "/" + payload.id + "/password", {
    ...payload,
    user_type: 4,
  });
};

const getRoomList = async (commit) => {
  const response = await http.get(ROOMS);
  commit("setRoomList", response.data);
};

const users = async (commit, query) => {
  const response = await http.get(GET_CHAT_USER, { params: query });
  commit("setUsers", response.data);
};

export default {
  getBodyshops,
  createBodyshops,
  deleteBodyshops,
  updateBodyshops,
  getBodyshopsServices,
  activateBodyshops,
  updateBodyshopsPassword,
  getBodyshopsList,
  users,
  getRoomList,
};
