import http from "@/services/http.service";
import { POSTING } from "@/constants/apiUrls";
import { toUsPhone } from "@/utils/number.utility";

const getPosting = async(commit, payload) => {
    const response = await http.get(POSTING, {
        params: {...payload },
    });
    commit("setPosting", response.data);
    commit("setPostingMeta", response.data);
};

const getPublicPosting = async(commit, payload) => {
    const response = await http.get(POSTING, {
        params: {...payload },
    });
    response.data.data.forEach((item) => {
        item.phone = toUsPhone(item.phone.substring(2));
    });
    commit("setPublicPosting", response.data);
    commit("setPublicPostingMeta", response.data);
};

const deletePosting = async(commit, payload) => {
    await http.delete(POSTING, { data: payload });
};

export default {
    getPosting,
    getPublicPosting,
    deletePosting,
};