import memberService from "@/services/member.service";

const state = {
  members: [],
  membersAndAssociates: [],
  services: [],
  membersList: [],
  membersAssociateList: [],
  meta: {},
  users: [],
  rooms: [],
};

const actions = {
  getMembers({ commit }, payload) {
    return memberService.getMembers(commit, payload);
  },

  getMembersAndAssociates({ commit }, payload) {
    return memberService.getMembersAndAssociates(commit, payload);
  },

  getMembersList({ commit }, payload) {
    return memberService.getMembersList(commit, payload);
  },

  getMembersAssociateList({ commit }, payload) {
    return memberService.getMembersAssociateList(commit, payload);
  },

  createMember({ commit }, payload) {
    return memberService.createMember(commit, payload);
  },
  activateMember({ commit }, payload) {
    return memberService.activateMember(commit, payload);
  },
  updateMemberPassword({ commit }, payload) {
    return memberService.updateMemberPassword(commit, payload);
  },

  deleteMember({ commit }, payload) {
    return memberService.deleteMember(commit, payload);
  },

  updateMember({ commit }, payload) {
    return memberService.updateMember(commit, payload);
  },

  getMemberServices({ commit }) {
    return memberService.getMemberServices(commit);
  },

  getRoomList({ commit }) {
    return memberService.getRoomList(commit);
  },

  createAssociate({ commit }, payload) {
    return memberService.createAssociate(commit, payload);
  },

  getUsers({ commit }, query) {
    return memberService.users(commit, query);
  },
};

const mutations = {
  setMembers(state, response) {
    state.members = response.data;
  },

  setMembersAndAssociates(state, response) {
    state.membersAndAssociates = response.data;
  },

  setMeta(state, response) {
    state.meta = response.meta;
  },

  setMemberServices(state, response) {
    state.services = response.data;
  },

  setMembersList(state, response) {
    state.membersList = response.data;
  },

  setRoomList(state, response) {
    state.rooms = response.data;
  },

  setMembersAssociateList(state, response) {
    state.membersAssociateList = response.data;
  },

  setUsers(state, response) {
    state.users = response.data;
  },
};

const getters = {
  getMembers(state) {
    return state.members;
  },

  getMembersAndAssociates(state) {
    return state.membersAndAssociates;
  },

  getMeta(state) {
    return state.meta;
  },

  getMemberServices(state) {
    return state.services.reverse();
  },

  getMembersList(state) {
    return state.membersList;
  },

  getRoomList(state) {
    return state.rooms;
  },

  getMembersAssociateList(state) {
    return state.membersAssociateList;
  },

  getUsers(state) {
    return state.users;
  },
};

export const members = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
