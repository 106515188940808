export const toUsFormat = {
  update: function (el) {
    const handler = function (e) {
      if (e.target.value) {
        const formattedValue = e.target.value
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !formattedValue[2]
          ? formattedValue[1]
          : "(" +
            formattedValue[1] +
            ") " +
            formattedValue[2] +
            (formattedValue[3] ? "-" + formattedValue[3] : "");
      }
    };
    el.addEventListener("input", handler);
  },
};
