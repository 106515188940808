import paymenthistoryService from "@/services/paymenthistory.service";

const state = {
  paymenthistory: [],
  paymenthistoryMeta: {},
  paymentSubscription: null,
  paymentReceipt: null,
};

const actions = {
  getPaymentHistory({ commit }, payload) {
    return paymenthistoryService.getPaymentHistory(commit, payload);
  },
  getPaymentSubscription({ commit }, payload) {
    return paymenthistoryService.getPaymentSubscription(commit, payload);
  },
  getPaymentReceiptUrl({ commit }, payload) {
    return paymenthistoryService.paymentReceiptUrl(commit, payload);
  }
};

const mutations = {
  setPaymentHistory(state, response) {
    state.paymenthistory = response.data;
  },

  setPaymentHistoryMeta(state, response) {
    state.paymenthistoryMeta = response.meta;
  },

  setPaymentSubscription(state, response) {
    state.paymentSubscription = response.data;
  },

  setPaymentReceiptUrl(state, response) {
    state.paymentReceipt = response;
  },
};

const getters = {
  getPaymentHistory(state) {
    return state.paymenthistory;
  },

  getPaymentHistoryMeta(state) {
    return state.paymenthistoryMeta;
  },

  getPaymentSubscription(state) {
    return state.paymentSubscription;
  },

  getPaymentReceiptUrl(state) {
    return state.paymentReceipt;
  },
};

export const paymenthistory = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
