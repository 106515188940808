export const VIEW_ADMIN = "view_admin";
export const MANAGE_ADMIN = "manage_admin";
export const VIEW_USER = "view_user";
export const MANAGE_USER = "manage_user";
export const VIEW_LEAD = "view_lead";
export const MANAGE_LEAD = "manage_lead";
export const VIEW_POSTING = "view_post";
export const MANAGE_POSTING = "manage_post";
export const MANAGE_NOTIFICATION = "manage_notification";
export const VIEW_NOTIFICATION = "view_notification";
export const VIEW_FINANCIAL_DASHBOARD = "view_financial";
export const MANAGE_FINANCIAL_DASHBOARD = "manage_financial";
export const VIEW_JANUS_DASHBOARD = "view_janus";
export const VIEW_NEWS = "view_news";
export const MANAGE_NEWS = "manage_news";
