import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import {
  VIEW_ADMIN,
  VIEW_USER,
  VIEW_LEAD,
  VIEW_POSTING,
  MANAGE_NOTIFICATION,
  VIEW_FINANCIAL_DASHBOARD,
  MANAGE_FINANCIAL_DASHBOARD,
  VIEW_JANUS_DASHBOARD,
  VIEW_NEWS,
  MANAGE_NEWS,
} from "@/constants/permission";
import { loadLanguageAsync } from "@/plugins/i18n";
Vue.use(VueRouter);

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Dashboard"),
  },
  {
    name: "Admin",
    path: "/pages/admin",
    component: () => import("@/views/Admin"),
    meta: { auth: VIEW_ADMIN },
  },
  {
    name: "Members",
    path: "/pages/members",
    component: () => import("@/views/Members"),
    meta: { auth: VIEW_USER },
  },
  {
    name: "Bodyshops",
    path: "/pages/bodyshops",
    component: () => import("@/views/Bodyshops"),
    meta: { auth: VIEW_USER },
  },
  {
    name: "Associates",
    path: "/pages/associates",
    component: () => import("@/views/Associates"),
    meta: { auth: VIEW_USER },
  },
  {
    name: "CoreBuying",
    path: "/pages/core-buying",
    component: () => import("@/views/CoreBuying"),
    meta: { auth: VIEW_USER },
  },
  {
    name: "PaymentHistory",
    path: "/pages/payment-history",
    component: () => import("@/views/PaymentHistory"),
    meta: { auth: VIEW_USER },
  },
  {
    name: "Leads",
    path: "/pages/leads",
    component: () => import("@/views/Leads"),
    meta: { auth: VIEW_LEAD },
  },
  {
    name: "PublicPosting",
    path: "/pages/public/posting",
    component: () => import("@/views/PublicPosting"),
    meta: { auth: VIEW_POSTING },
  },
  {
    name: "Posting",
    path: "/pages/private/posting",
    component: () => import("@/views/Posting"),
    meta: { auth: VIEW_POSTING },
  },
  {
    name: "Notification",
    path: "/pages/notification",
    component: () => import("@/components/notification/Notification"),
    meta: { auth: MANAGE_NOTIFICATION },
  },
  {
    name: "FinancialDashboard",
    path: "/pages/financial-dashboard",
    component: () => import("@/views/FinancialDashboard"),
    meta: { auth: VIEW_FINANCIAL_DASHBOARD },
  },
  {
    name: "DuePayment",
    path: "/pages/due-payment",
    component: () => import("@/views/DuePayment"),
    meta: { auth: VIEW_FINANCIAL_DASHBOARD },
  },
  {
    name: "TransactionHistory",
    path: "/pages/transaction-history/:id",
    component: () => import("@/views/TransactionHistory"),
    meta: { auth: MANAGE_FINANCIAL_DASHBOARD },
  },
  {
    name: "JanusDashboard",
    path: "/pages/janus-dashboard",
    component: () => import("@/views/JanusDashboard"),
    meta: { auth: VIEW_JANUS_DASHBOARD },
  },
  {
    name: "News",
    path: "/pages/news",
    component: () => import("@/views/News"),
    meta: { auth: VIEW_NEWS },
  },
  {
    name: "CreateAndEditNews",
    path: "/pages/news/manage",
    props: true,
    component: () => import("@/components/news/CreateAndEditNews"),
    meta: { auth: MANAGE_NEWS },
  },
  {
    path: "/",
    name: "Login",
    component: () => import("@/views/Login"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const authMeta = to.meta.auth;
  let authorization = [];
  const lang = localStorage.getItem("lang")
    ? localStorage.getItem("lang")
    : "en";
  await loadLanguageAsync(lang);
  const publicPages = ["Login"];
  if (to.name == "Login" && store.getters["user/isLoggedIn"]) {
    next({
      path: "/dashboard",
    });
  } else if (
    !publicPages.includes(to.name) &&
    !store.getters["user/isLoggedIn"]
  ) {
    next({
      name: "Login",
    });
  } else {
    if (store.getters["user/isLoggedIn"] && authMeta) {
      authorization = store.getters["user/getUser"].permission.map(
        (val) => val.name
      );
      const checkAuthorization = authorization.includes(authMeta);
      if (!checkAuthorization) {
        next({
          path: from.path,
        });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
