import http from "@/services/http.service";
import {
  DUE_PAYMENTS,
} from "@/constants/apiUrls";

const getDuePayments = async (commit, payload) => {
  const response = await http.get(DUE_PAYMENTS, {
    params: { ...payload },
  });
  commit("setDuePayments", response.data);
  commit("setDuePaymentsMeta", response.data);
};

export default {
  getDuePayments,
};
