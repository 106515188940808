import http from "@/services/http.service";
import {
  ADMINS,
  MANAGE_ADMIN,
  ADMIN_CHANGE_PASSWORD,
} from "@/constants/apiUrls";

const getAdmins = async (commit) => {
  const response = await http.get(ADMINS);
  commit("setAdmins", response.data);
  commit("setMeta", response.data);
};

const createAdmin = async (commit, payload) => {
  await http.post(ADMINS, payload);
};

const updateAdmin = async (commit, adminId, payload) => {
  const url = MANAGE_ADMIN.replace("{admin_id}", adminId);
  await http.put(url, payload);
};

const deleteAdmin = async (commit, adminId) => {
  const url = MANAGE_ADMIN.replace("{admin_id}", adminId);
  await http.delete(url);
};

const updateAdminPassword = async (commit, payload) => {
  await http.put(
    ADMIN_CHANGE_PASSWORD + "/" + payload.id + "/password",
    payload
  );
};

export default {
  getAdmins,
  createAdmin,
  updateAdmin,
  deleteAdmin,
  updateAdminPassword,
};
