import adminService from "@/services/admin.service";

const state = {
  admins: [],
  meta: {},
};

const actions = {
  getAdmins({ commit }) {
    return adminService.getAdmins(commit);
  },

  createAdmin({ commit }, payload) {
    return adminService.createAdmin(commit, payload);
  },

  updateAdmin({ commit }, payload) {
    return adminService.updateAdmin(commit, payload.adminId, payload.payload);
  },

  deleteAdmin({ commit }, payload) {
    return adminService.deleteAdmin(commit, payload.adminId);
  },

  updateAdminPassword({ commit }, payload) {
    return adminService.updateAdminPassword(commit, payload);
  },
};

const mutations = {
  setAdmins(state, response) {
    state.admins = response.data;
  },

  setMeta(state, response) {
    state.meta = response.meta;
  },
};

const getters = {
  getAdmins(state) {
    return state.admins;
  },

  getMeta(state) {
    return state.meta;
  },
};

export const admins = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
