import axios from "axios";
import http from "@/services/http.service";
import tokenService from "./token.service";
import { NEWS, NEWS_UPLOAD_MEDIA } from "@/constants/apiUrls";

const getNewsList = async (commit, payload) => {
  const response = await http.get(NEWS, {
    params: payload,
  });
  commit("setNewsList", response.data);
  commit("setMeta", response.data);
};

const createNews = async (commit, payload) => {
  await http.post(NEWS, payload);
};

const deleteNews = async (commit, payload) => {
  await http.delete(NEWS + "/" + payload.id);
};

const updateNews = async (commit, payload) => {
  await http.put(NEWS + "/" + payload.id, payload);
};

const uploadMedia = async (commit, formData) => {
  const response = await axios.post(
    process.env.VUE_APP_API_HOST + NEWS_UPLOAD_MEDIA,
    formData,
    {
      headers: {
        Authorization: `Bearer ${tokenService.getAccessToken()}`,
      },
    }
  );
  commit("setNewsMediaResponse", response);
  return response;
};

export default {
  createNews,
  deleteNews,
  updateNews,
  getNewsList,
  uploadMedia,
};
