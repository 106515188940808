import axios from "axios";
import { isObject } from "lodash";
import tokenService from "./token.service";
import { INTERNAL_SERVER_ERROR, UNAUTHORIZED } from "@/constants/httpStatus";
import store from "../store";
import router from "../router";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
});

const errorHandler = (error) => {
  let errorObject = { status: error.response.status };

  if (isObject(error.response.data.error)) {
    errorObject.message = error.response.data.message;
  } else if (isObject(error.response.data.errors)) {
    errorObject.message = error.response.data.errors;
  } else {
    errorObject.message = error.response.data.message;
  }

  return Promise.reject(errorObject);
};

http.interceptors.request.use(
  (config) => {
    if (tokenService.getAccessToken()) {
      config.headers.common[
        "Authorization"
      ] = `Bearer ${tokenService.getAccessToken()}`;
    }

    config.headers.common["Content-type"] = "application/json";

    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response || error.response.status === INTERNAL_SERVER_ERROR) {
      return Promise.reject({
        status: INTERNAL_SERVER_ERROR,
        message: "some error occured",
      });
    }
    if (error.response && error.response.status === UNAUTHORIZED) {
      store.commit("user/logout");
      router.push("/");
      return Promise.reject({
        status: UNAUTHORIZED,
        message: error.response.data.message,
      });
    }

    return errorHandler(error);
  }
);

if (tokenService.getAccessToken()) {
  http.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${tokenService.getAccessToken()}`;
}

export default http;
