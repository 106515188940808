import Vue from "vue";
import VueI18n from "vue-i18n";
import es from "@/locale/es";
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from "@/constants/locale";
import axios from "axios";
Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: FALLBACK_LANGUAGE,
  messages: { es },
});

const defaultLangs = ["es"];

if (!localStorage.getItem("lang")) {
  let ln = process.env.VUE_APP_LANGUAGE ? process.env.VUE_APP_LANGUAGE : "es";
  localStorage.setItem("lang", ln);
}

const setLanguage = (lang) => {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
};

export const loadLanguageAsync = (lang) => {
  if (i18n.locale === lang) {
    return Promise.resolve(setLanguage(lang));
  }

  if (defaultLangs.includes(lang)) {
    return Promise.resolve(setLanguage(lang));
  }

  return import(`@/locale/${lang}.json`).then((messages) => {
    i18n.setLocaleMessage(lang, messages.default);
    defaultLangs.push(lang);
    return setLanguage(lang);
  });
};
