import http from "@/services/http.service";
import { PAYMENT_HISTORY, PAYMENT_SUBSCRIPTION, PAYMENT_RECEIPT_URL } from "@/constants/apiUrls";

const getPaymentHistory = async (commit, payload) => {
  const response = await http.get(PAYMENT_HISTORY, {
    params: payload,
  });
  commit("setPaymentHistory", response.data);
  commit("setPaymentHistoryMeta", response.data);
};

const getPaymentSubscription = async (commit, payload) => {
  const response = await http.get(PAYMENT_SUBSCRIPTION, {
    params: payload,
  });
  commit("setPaymentSubscription", response.data);
};

const paymentReceiptUrl = async (commit, payload) => {
  const response = await http.get(PAYMENT_RECEIPT_URL, {
    params: payload,
  });
  commit("setPaymentReceiptUrl", response.data);
};

export default {
  getPaymentHistory,
  getPaymentSubscription,
  paymentReceiptUrl
};
