import http from "@/services/http.service";
import { NOTIFICATIONS } from "@/constants/apiUrls";

const sendNotification = async (commit, payload) => {
  await http.post(NOTIFICATIONS, payload);
};

export default {
  sendNotification,
};
