import http from "@/services/http.service";
import { PAYMENT_METHOD } from "@/constants/apiUrls";

const getPaymentMethods = async (commit) => {
  const response = await http.get(PAYMENT_METHOD);
  commit("setPaymentMethods", response.data);
};

export default {
  getPaymentMethods,
};
