import duePaymentService from "@/services/duePayment.service";

const state = {
  duePayments: [],
  duePaymentsMeta: {},
};

const actions = {
  getDuePayments({ commit }, payload) {
    return duePaymentService.getDuePayments(commit, payload);
  }
};

const mutations = {
  setDuePayments(state, response) {
    state.duePayments = response.data;
  },

  setDuePaymentsMeta(state, response) {
    state.duePaymentsMeta = response.meta;
  },
};

const getters = {
  getDuePayments(state) {
    return state.duePayments;
  },

  getDuePaymentsMeta(state) {
    return state.duePaymentsMeta;
  },
};

export const duePayment = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
