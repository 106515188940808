import leadService from "@/services/lead.service";

const state = {
  leads: [],
  meta: {},
};

const actions = {
  getLeads({ commit }, payload) {
    return leadService.getLeads(commit, payload);
  },
  deleteLead({ commit }, payload) {
    return leadService.deleteLead(commit, payload);
  },
};

const mutations = {
  setLeads(state, response) {
    state.leads = response.data;
  },

  setMeta(state, response) {
    state.meta = response.meta;
  },
};

const getters = {
  getLeads(state) {
    return state.leads;
  },

  getMeta(state) {
    return state.meta;
  },
};

export const leads = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
