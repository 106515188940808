import coreBuyingService from "@/services/coreBuying.service";

const state = {
  coreBuying: [],
  services: [],
  coreBuyingList: [],
  meta: {},
  users: [],
  rooms: [],
};

const actions = {
  getCoreBuying({ commit }, payload) {
    return coreBuyingService.getCoreBuying(commit, payload);
  },

  getCoreBuyingList({ commit }, payload) {
    return coreBuyingService.getCoreBuyingList(commit, payload);
  },

  createCoreBuying({ commit }, payload) {
    return coreBuyingService.createCoreBuying(commit, payload);
  },

  activateCoreBuying({ commit }, payload) {
    return coreBuyingService.activateCoreBuying(commit, payload);
  },

  updateCoreBuyingPassword({ commit }, payload) {
    return coreBuyingService.updateCoreBuyingPassword(commit, payload);
  },

  deleteCoreBuying({ commit }, payload) {
    return coreBuyingService.deleteCoreBuying(commit, payload);
  },

  updateCoreBuying({ commit }, payload) {
    return coreBuyingService.updateCoreBuying(commit, payload);
  },
};

const mutations = {
  setCoreBuying(state, response) {
    state.coreBuying = response.data;
  },

  setMeta(state, response) {
    state.meta = response.meta;
  },

  setCoreBuyingList(state, response) {
    state.coreBuyingList = response.data;
  },
};

const getters = {
  getCoreBuying(state) {
    return state.coreBuying;
  },

  getMeta(state) {
    return state.meta;
  },

  getCoreBuyingList(state) {
    return state.coreBuyingList;
  },
};

export const coreBuying = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
