import postingService from "@/services/posting.service";

const state = {
  posting: [],
  postingPublic: [],
  meta: {},
  publicPostMeta: {},
};

const actions = {
  getPosting({ commit }, payload) {
    return postingService.getPosting(commit, payload);
  },

  getPublicPosting({ commit }, payload) {
    return postingService.getPublicPosting(commit, payload);
  },

  deletePosting({ commit }, payload) {
    return postingService.deletePosting(commit, payload);
  },
};

const mutations = {
  setPosting(state, response) {
    state.posting = response.data;
  },

  setPublicPosting(state, response) {
    state.postingPublic = response.data;
  },

  setPostingMeta(state, response) {
    state.meta = response.meta;
  },

  setPublicPostingMeta(state, response) {
    state.publicPostMeta = response.meta;
  },
};

const getters = {
  getPosting(state) {
    return state.posting;
  },

  getPublicPosting(state) {
    return state.postingPublic;
  },

  getPostingMeta(state) {
    return state.meta;
  },

  getPublicPostingMeta(state) {
    return state.publicPostMeta;
  },
};

export const posting = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
