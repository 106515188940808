import http from "@/services/http.service";
import {
  MEMBERS,
  MEMBER_CHANGE_PASSWORD,
  MEMBERSLIST,
} from "@/constants/apiUrls";
import { toUsPhone } from "../utils/number.utility.js";

const getCoreBuying = async (commit, payload) => {
  const response = await http.get(MEMBERS, {
    params: { user_type: 3, ...payload }, // type = scarp (core buying)},
  });
  response.data.data.forEach((item) => {
    item.user_detail.company_phone = item.user_detail
      ? toUsPhone(item.user_detail.company_phone)
      : "";
    item.user_detail.representative_phone = item.user_detail
      ? toUsPhone(item.user_detail.representative_phone)
      : "";
    item.roomId = item.rooms.length ? item.rooms[0].id : "";
  });
  commit("setCoreBuying", response.data);
  commit("setMeta", response.data);
};

const getCoreBuyingList = async (commit) => {
  const response = await http.get(MEMBERSLIST, {
    params: { user_type: 3 }, // type = scarp (core buying)},
  });
  commit("setCoreBuyingList", response.data);
};

const createCoreBuying = async (commit, payload) => {
  await http.post(MEMBERS, { user_type: 3, ...payload });
};

const deleteCoreBuying = async (commit, payload) => {
  await http.delete(MEMBERS + "/" + payload.id);
};

const updateCoreBuying = async (commit, payload) => {
  await http.put(MEMBERS + "/" + payload.id, {
    user_type: 3,
    ...payload,
  });
};

const activateCoreBuying = async (commit, payload) => {
  await http.put(MEMBERS + "/" + payload.id + "/activation", {
    user_type: 3,
    ...payload,
  });
};

const updateCoreBuyingPassword = async (commit, payload) => {
  await http.put(MEMBER_CHANGE_PASSWORD + "/" + payload.id + "/password", {
    user_type: 3,
    ...payload,
  });
};

export default {
  getCoreBuying,
  createCoreBuying,
  deleteCoreBuying,
  updateCoreBuying,
  activateCoreBuying,
  updateCoreBuyingPassword,
  getCoreBuyingList,
};
