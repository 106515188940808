import financialDashboardService from "@/services/financialDashboard.service";

const state = {
  members: [],
  meta: {},
  transactionMeta: {},
  transactionHistory: [],
};

const actions = {
  getMembers({ commit }, payload) {
    return financialDashboardService.getMembers(commit, payload);
  },

  getTransactionHistory({ commit }, payload) {
    return financialDashboardService.getTransactionHistory(commit, payload);
  },

  clearDuePayment({ commit }, payload) {
    return financialDashboardService.clearDuePayment(commit, payload);
  },
};

const mutations = {
  setMembers(state, response) {
    state.members = response.data;
  },

  setTransactionHistory(state, response) {
    state.transactionHistory = response.data;
  },

  setMeta(state, response) {
    state.meta = response.meta;
  },

  setTransactionMeta(state, response) {
    state.transactionMeta = response.meta;
  },
};

const getters = {
  getMembers(state) {
    return state.members;
  },

  getTransactionHistory(state) {
    return state.transactionHistory;
  },

  getMeta(state) {
    return state.meta;
  },

  getTransactionMeta(state) {
    return state.transactionMeta;
  },
};

export const financialDashboard = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
