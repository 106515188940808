import paymentMethodService from "@/services/paymentMethod.service";

const state = {
  paymentMethods: [],
};

const actions = {
  getPaymentMethods({ commit }) {
    return paymentMethodService.getPaymentMethods(commit);
  },
};

const mutations = {
  setPaymentMethods(state, response) {
    state.paymentMethods = response.data;
  },
};

const getters = {
  getPaymentMethods(state) {
    return state.paymentMethods;
  },
};

export const paymentMethod = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
