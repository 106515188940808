import dashboardService from "@/services/dashboard.service";

const state = {
  dashboardStatus: {},
};

const actions = {
  getDashbaordStatus({ commit }, payload) {
    return dashboardService.getDashbaordStatus(commit, payload);
  },
};

const mutations = {
  setDashbaordStatus(state, response) {
    state.dashboardStatus = response.data;
  },
};

const getters = {
  getDashbaordStatus(state) {
    return state.dashboardStatus;
  },
};

export const dashboard = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
