import http from "@/services/http.service";
import { DASHBOARD } from "@/constants/apiUrls";

const getDashbaordStatus = async (commit, payload) => {
  const response = await http.get(DASHBOARD, {
    params: payload,
  });
  commit("setDashbaordStatus", response.data);
};

export default {
  getDashbaordStatus,
};
