import http from "@/services/http.service";
import { LEADS } from "@/constants/apiUrls";
import { toUsPhone } from "../utils/number.utility.js";

const getLeads = async (commit, payload) => {
  const response = await http.get(LEADS, { params: payload });
  response.data.data.forEach((item) => {
    item.representative_phone_number = toUsPhone(
      item.representative_phone_number
    );
  });
  commit("setLeads", response.data);
  commit("setMeta", response.data);
};

const deleteLead = async (commit, payload) => {
  await http.delete(LEADS + "/" + payload.id);
};

export default {
  getLeads,
  deleteLead,
};
