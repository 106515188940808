import bodyshopsService from "@/services/bodyshops.service";

const state = {
  bodyshops: [],
  services: [],
  bodyshopsList: [],
  meta: {},
  users: [],
  rooms: [],
};

const actions = {
  getBodyshops({ commit }, payload) {
    return bodyshopsService.getBodyshops(commit, payload);
  },

  getBodyshopsList({ commit }, payload) {
    return bodyshopsService.getBodyshopsList(commit, payload);
  },

  createBodyshops({ commit }, payload) {
    return bodyshopsService.createBodyshops(commit, payload);
  },
  activateBodyshops({ commit }, payload) {
    return bodyshopsService.activateBodyshops(commit, payload);
  },
  updateBodyshopsPassword({ commit }, payload) {
    return bodyshopsService.updateBodyshopsPassword(commit, payload);
  },

  deleteBodyshops({ commit }, payload) {
    return bodyshopsService.deleteBodyshops(commit, payload);
  },

  updateBodyshops({ commit }, payload) {
    return bodyshopsService.updateBodyshops(commit, payload);
  },

  getBodyshopsServices({ commit }) {
    return bodyshopsService.getBodyshopsServices(commit);
  },

  getRoomList({ commit }) {
    return bodyshopsService.getRoomList(commit);
  },

  getUsers({ commit }, query) {
    return bodyshopsService.users(commit, query);
  },
};

const mutations = {
  setBodyshops(state, response) {
    state.bodyshops = response.data;
  },

  setMeta(state, response) {
    state.meta = response.meta;
  },

  setBodyshopsServices(state, response) {
    state.services = response.data;
  },

  setBodyshopsList(state, response) {
    state.bodyshopsList = response.data;
  },

  setRoomList(state, response) {
    state.rooms = response.data;
  },

  setUsers(state, response) {
    state.users = response.data;
  },
};

const getters = {
  getBodyshops(state) {
    return state.bodyshops;
  },

  getMeta(state) {
    return state.meta;
  },

  getBodyshopsServices(state) {
    return state.services.reverse();
  },

  getBodyshopsList(state) {
    return state.bodyshopsList;
  },

  getRoomList(state) {
    return state.rooms;
  },

  getUsers(state) {
    return state.users;
  },
};

export const bodyshops = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
