<template>
  <v-app id="inspire">
    <Loading :active="loader" loader="dots" color="#4CAF50" />
    <Header v-model="expandOnHover" v-if="isAuthenticated" />
    <Sidebar :expand-on-hover.sync="expandOnHover" v-if="isAuthenticated" />
    <api-error-message></api-error-message>
    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
    Header: () => import("@/components/layout/Header"),
    Sidebar: () => import("@/components/layout/Sidebar"),
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      expandOnHover: false,
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "user/isLoggedIn",
      loader: "getLoaderStatus",
    }),
  },
};
</script>
