import notificationService from "@/services/notification.service";

const actions = {
  sendNotification({ commit }, payload) {
    return notificationService.sendNotification(commit, payload);
  },
};

export const notification = {
  namespaced: true,
  actions,
};
